import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card, Heading, Flex, Paragraph } from 'reakit'

import {
	Container,
	Body,
	Form,
	TopBar,
	HomeFooter
} from '../components/layouts'

import {
	Link,
} from '../components/ui'

import { forgot, clearNotification } from '../factory'

import Layout from '../components/layout'

import theme from '../theme/content'

class Forgot extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			formValidate: null,
		}

		this.setValues = this.setValues.bind(this)
		this.submitForm = this.submitForm.bind(this)
	}

	componentDidMount() {
		this.props.clearMessages()
	}

	setValues(e) {
		if (e.target) {
			this.setState({
				[e.target.name]: e.target.value,
				formValidate: null,
			})
		}
	}

	submitForm(e) {
		e.preventDefault()

		if (this.state.email && !this.state.formValidate) {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
				this.setState({
					formValidate: null,
				})

				this.props.forgotPassword(this.state.email)
			} else {
				this.setState({
					formValidate: "Please use the correct email format.",
				})
			}
		} else {
			this.setState({
				formValidate: "Please fill the form.",
			})
		}
	}

	render() {
		const content = theme.pagesContent.forgot
		return (
			<Layout>
				<TopBar />
				<Container maxHeight="100vh">
					<Body bgColor={'transparent'} bg={content.bg} maxWidth="100%">
						<Flex
							width={'100%'}
							height={'100vh'}
							alignItems={'center'}
							justifyContent={'center'}
							flexDirection={'column'}
						>
							<Card
								background={'transparent'}
								boxShadow="none"
								width="100%"
								maxWidth="450px"
							>
								<Heading
									as="h1"
									fontSize="35px"
									margin="0 0 0.5em 0"
									lineHeight="1em"
								>
									{content.title}
								</Heading>
								<Flex margin="0 0 1rem 0" column>
									<Form
										fields={content.forgotForm.fields}
										setValues={this.setValues}
										submitForm={this.submitForm}
										submitBtnText={this.props.peding ? 'Sending Link...' : content.forgotForm.submitField.text}
										error={this.props.error || this.state.formValidate}
									/>
									<Paragraph>{this.props.success && 'Sent reset password link'}</Paragraph>
								</Flex>
								<Flex margin="0" row>
									<Heading as="h6" margin="-0.5em 1em 0 0">
										<Link as="a" href={'/login'}>
											{content.login.text}
										</Link>
									</Heading>
								</Flex>
							</Card>
						</Flex>
					</Body>
				</Container>
				<HomeFooter />
			</Layout>
		)
	}
}

Forgot.propTypes = {
	forgotPassword: PropTypes.func,
	clearMessages: PropTypes.func,
	peding: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.string,
}

const mapStateToProps = (state) => {
	return {
		peding: state.authState.pending,
		success: state.authState.success,
	 	error: state.authState.error,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		forgotPassword: (email) => dispatch(forgot(email)),
		clearMessages: () => dispatch(clearNotification()),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Forgot)
